{
  "all-top-nav-link-1": "Główna",
  "all-top-nav-link-2": "Kariera",
  "all-top-nav-link-3": "Founderzy",
  "all-top-nav-link-4": "Blog",
  "all-top-nav-link-5": "Newsletter",
  "all-cookie-bar-title-1": "Zgódź się,",
  "all-cookie-bar-title-2": "mamy ciasteczka",
  "all-cookie-bar-link": "Polityka prywatności",
  "all-cookie-bar-btn-cta-1": "Akceptuj",
  "all-cookie-bar-btn-cta-2": "Odrzuć",
  "home-top-nav-btn-cta": "Dołącz do zespołu",
  "home-intro-headline": "#STARTUP STUDIO Z POLSKI",
  "home-intro-title-1": "Zajmujemy się",
  "home-intro-title-2": "startupów",
  "home-intro-title-3": "od 2006",
  "home-intro-btn-cta": "Dołącz do nas!",
  "home-journal-btn-cta": "Odkryj nasz blog",
  "careers-top-nav-btn-cta": "Oferty pracy",
  "careers-intro-title-1": "Lataj",
  "careers-intro-title-2": "z Red Sky!",
  "careers-intro-description": "Jesteśmy zorientowanym na wyniki zespołem ekspertów wspierającym nasz ekosystem startupów",
  "careers-intro-btn-cta-1": "Dołącz do nas!",
  "careers-intro-btn-cta-2": "Obejżyj wideo",
  "careers-listings-input-search": "Przeszukaj oferty",
  "founder-top-nav-btn-cta": "Zaaplikuj teraz",
  "founder-intro-title-1": "Poprowadzimy",
  "founder-intro-title-2": "twoją ścieżkę",
  "founder-intro-btn-cta": "Dowiedz się jak"
}
