import { initTranslator } from "./translator";
import { initGsap } from "./gsapControls";
import { initCookiePolicy } from "./cookies";
// import { initMobileNav } from "./mobileNav";
import { initActiveNav, initCtaNav, initDropdownNav, initMobileNav } from "./nav";
import { initSmoothScroll } from "./smoothScroll";
import { initJobListingsFetch } from "./job-listings";
import { initJobListingsSearch } from "./job-listings";
import { initJobListingsFilter } from "./job-listings";
import { initRecruitmentSteps } from "./recruitmentSteps";
import { initVideo } from "./video";
import { delay, windowReady } from "./utils";
import { initSocialSlider } from "./socialSlider";
import { initTolstoy } from "./tolstoy";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
// import { initFloatingPlayer } from "./floatingPlayer";

export default init = () => {
  windowReady(() => {
    initJobListingsFetch(true, 10);
    delay(initGsap('Careers'));
    initTranslator();
    initJobListingsSearch();
    initJobListingsFilter();
    initSmoothScroll();
    initActiveNav();
    initCtaNav();
    initDropdownNav();
    initMobileNav();
    initCookiePolicy();
    initRecruitmentSteps();
    initVideo();
    initSocialSlider();
    delay(initTolstoy('Careers'));
  });
};
