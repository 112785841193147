export const initVideo = () => {
  const btnScrollToVideoAndPlay = document.getElementById('RS-careers-btn-scroll-to-video-and-play');
  const videoOverlay = document.getElementById('RS-video-overlay');
  const videoCareers = document.getElementById('RS-careers-video');
  const scrollTimeout = 900;

  btnScrollToVideoAndPlay.addEventListener('click', () => {
    videoOverlay.classList.add('RS-video-overlay--hidden');
    setTimeout(() => {
      videoCareers.play();
    }, scrollTimeout);
  });

  videoOverlay.addEventListener('click', () => {
      videoOverlay.classList.add('RS-video-overlay--hidden');
      videoCareers.play();
  });

  // let hasScrolled = false;

  // window.addEventListener('scroll', () => {
  //   if (!hasScrolled) {
  //     const teamMemberVideos = document.querySelectorAll('.RS-team-member__video');
  
  //     teamMemberVideos.forEach(video => {
  //       video.preload = 'metadata';
  //       console.log('changed preload to metadata for video elements.');
  //     });
  //   }
  //   hasScrolled = true;
  // });


  
};