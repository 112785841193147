import { ScrollTrigger } from "gsap/ScrollTrigger.js";

export const initJobListingsFetch = (createFiltersFlag, numberOfOffers) => {
  const jobListingsEl = document.getElementById('job-listings');
  const jobListingsArticle = document.getElementById("RS-job-listings");
  const jobFiltersEl = document.getElementById("RS-filters-careers");
  const jobFiltersAvailable = [];

  // const storedLanguage = localStorage.getItem('language');

  fetch('https://redsky.recruitee.com/api/offers/')
    .then(response => response.json())
    .then(result => {
      let offers = result['offers'];
      let postHTML = '';
      let isRemote = '';
      let remoteFilterCreated = false;
      if(offers.length){
        for (var i = 0; i < offers.length; i++) {
          if(i < numberOfOffers){
            if (offers[i].remote == true){
              // if(storedLanguage === 'pl'){
              //   isRemote = 'Zdalnie •';
              // }else{
                isRemote = 'Remote •';
              // }
            }else{
              isRemote = '';
            }
            postHTML += `<a href="${'https://redsky.recruitee.com/o/' + offers[i].slug}" target="_blank" class="RS-job-listing">
                          <p class="RS-job-listing-title"><span>${offers[i].title}<span> <span class="RS-inline-icon RS-inline-icon-external-link">
                          <svg aria-hidden="true" data-prefix="fal" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14" viewBox="0 0 448 512"><path fill="currentColor" d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg></span></p>
                          <p class="RS-job-listing-location"><span class="RS-job-listing-remote">${isRemote}</span> ${offers[i].country}, <span class="RS-job-listing-city">${offers[i].city}</span></p>
                        </a>`;
          }
          jobListingsEl.innerHTML = postHTML;

          if (!jobFiltersAvailable.includes(offers[i].city)) {
            jobFiltersAvailable.push(offers[i].city);
          }
          if (offers[i].remote == true && remoteFilterCreated == false) {
            remoteFilterCreated = true;
            // if(storedLanguage === 'pl'){
            //   jobFiltersAvailable.push('Zdalnie');
            // }else{
              jobFiltersAvailable.push('Remote');
            // }
          }
        }
        if(createFiltersFlag == true){
          createFilterButtons(jobFiltersAvailable);
        }
      }else{
        jobListingsArticle.remove();
      }
      
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    })
};

export function createFilterButtons(jobFiltersAvailable) {

  const filtersParent = document.getElementById("RS-filters-job-listings");
  const locations = jobFiltersAvailable;

  for (const location of locations) {
    const filterButton = document.createElement("span");
    filterButton.textContent = location;
    filterButton.classList.add("RS-filter-job-listings-location");
    filtersParent.appendChild(filterButton);
  }
}


export const initJobListingsSearch = () => {
  const searchInput = document.getElementById("RS-search-input-careers");
  const entries = document.getElementById("job-listings").getElementsByClassName("RS-job-listing");
  const emptyState = document.getElementById("RS-job-listings-empty-state");
  const filters = document.getElementById("RS-filters-job-listings").getElementsByClassName("RS-filter-job-listings-location");

  searchInput.addEventListener("input", () => {

    for (const filter of filters) {
      filter.classList.remove('is-active');
     }

     filters[0].classList.add('is-active');

    const query = searchInput.value.toLowerCase();
    let numResults = 0;

    for (const entry of entries) {
      const text = entry.textContent.toLowerCase();
      if (text.includes(query)) {
        entry.style.display = "block";
        numResults++;
      } else {
        entry.style.display = "none";
      }
    }

    if (numResults === 0) {
      emptyState.style.display = "flex";
    } else {
      emptyState.style.display = "none";
    }

  });
};


export const initJobListingsFilter = () => {
  const entries = document.getElementById("job-listings").getElementsByClassName("RS-job-listing");
  const filtersList = document.getElementById('RS-filters-job-listings');
  const filters = document.getElementById("RS-filters-job-listings").getElementsByClassName("RS-filter-job-listings-location");
  const emptyState = document.getElementById("RS-job-listings-empty-state");
  const searchInput = document.getElementById("RS-search-input-careers");

  filtersList.addEventListener('click', (event) => {
    emptyState.style.display = "none";
    searchInput.value = "";
    if (event.target.classList.contains('RS-filter-job-listings-location')) {
      const filterText = event.target.textContent.toLowerCase();

      for (const filter of filters) {
       filter.classList.remove('is-active');
      }
      event.target.classList.add('is-active');

      Array.from(entries).forEach((entry) => {
        const location = entry.querySelector('.RS-job-listing-city');
        const remote = entry.querySelector('.RS-job-listing-remote');

        if (location.textContent.toLowerCase().indexOf(filterText) !== -1 || event.target.id === 'RS-filter-job-listings-all-locations' || remote.textContent.toLowerCase().indexOf(filterText) !== -1) {
          entry.style.display = 'block';
        } else {
          entry.style.display = 'none';
        }
      });
    }else{
      emptyState.style.display = "flex";
    }
  });

}