import pl from './locales/pl.json';
import en from './locales/en.json';

export const initTranslator = () => {
  // const translations = { pl, en };


  // function translate(language) {
  //   const elements = document.querySelectorAll('[data-i18n]');

  //   elements.forEach(element => {
  //     const key = element.getAttribute('data-i18n');
  //     if (translations[language] && translations[language][key]) {
  //       element.textContent = translations[language][key];
  //       if(key === "home-journal-btn-cta"){
  //         element.parentElement.setAttribute('href', 'https://red-sky.com/blog/');
  //       }
  //       if(key === "careers-intro-title-1"){
  //         element.setAttribute('title', translations[language][key]);
  //       }
  //       if(key === "careers-listings-input-search"){
  //         element.setAttribute('placeholder', translations[language][key]);
  //       }
  //     } else {
  //       console.warn(`Translation not found for key: ${key}`);
  //     }
  //   });
  // }


  // const langSwitch = document.getElementById('RS-lang-switch');


  // langSwitch.addEventListener('click', (event) => {
  //   language = event.target.id;
  //   localStorage.setItem('language', language);
  //   if (language === 'pl') {
  //     url.searchParams.set('lang', language);
  //   } else {
  //     url.searchParams.delete('lang');
  //   }
  //   window.history.pushState({}, '', url);
  //   location.reload();
  // });

  // const url = new URL(window.location);
  // let language = url.searchParams.get('lang') || localStorage.getItem('language');

  // if(language){
  //   localStorage.setItem('language', language);
  //   if(language === 'pl'){
  //     langSwitch.querySelector('#pl').classList.add('RS-button--lang-active');
  //     langSwitch.querySelector('#en').classList.remove('RS-button--lang-active');
  //     url.searchParams.set('lang', language);
  //     document.documentElement.lang = 'pl';
  //     window.history.pushState({}, '', url);
  //   }else{
  //     langSwitch.querySelector('#pl').classList.remove('RS-button--lang-active');
  //     langSwitch.querySelector('#en').classList.add('RS-button--lang-active');
  //     url.searchParams.delete('lang');
  //     document.documentElement.lang = 'en';
  //     window.history.pushState({}, '', url);
  //   }
  //   translate(language);
  // }

};